import moment from "moment";
import React, { useState, useEffect, useRef } from "react";
import { useBlog, useListPost } from "../../hooks/methods/blog";
import Layout from "../../templates/MainTemplate";

import "moment/locale/pt-br";
import { RiCalendarCheckLine, RiAccountCircleLine } from "react-icons/ri";
import { BsShare, BsLinkedin, BsFacebook } from "react-icons/bs";
import Seo from "../../components/Seo";
import {
  ContainerPost,
  ContentPost,
  FooterMostContent,
  LineSeparator,
  ListMoreContent,
  MetaPost,
} from "../../view/blog/styledPost";
import { CardPost } from "../../components/CardPost/cardPost";
import { Dropdown } from "../../components/Dropdown/dropdown";
import { ReadingBar } from "../../components/Readingbar/readingBar";

const noRedirectPosts = {
  "mercado-pet-cresce-104-no-brasil":
    "https://www.matera.com/br/cinnecta-agora-e-matera-insights/",
  "inovacao-na-analise-e-concessao-de-credito-imobiliario":
    "https://www.matera.com/br/blog/como-inovar-na-concessao-de-credito",
  "historia-da-cinnecta":
    "https://www.matera.com/br/cinnecta-agora-e-matera-insights/",
  "5-dicas-para-potencializar-sua-cultura-de-dados":
    "https://www.matera.com/br/blog/cultura-data-driven",
  "campanhas-de-black-friday":
    "https://www.matera.com/br/blog/voucher-de-desconto-black-friday/",
  "estrategia-de-pricing-imobiliario":
    "https://www.matera.com/br/blog/sensibilidade-ao-preco",
  "cultura-de-dados-como-implementar":
    "https://www.matera.com/br/blog/cultura-data-driven",
  "fraude-e-servicos-mobile-no-carnaval":
    "https://www.matera.com/br/cinnecta-agora-e-matera-insights/",
  "analise-de-credito-e-covid19":
    "https://www.matera.com/br/blog/comportamento-do-consumidor-impactos-do-covid-19/",
  "solucao-antifraude-comprar-ou-desenvolver":
    "https://www.matera.com/br/cinnecta-agora-e-matera-insights/",
  "somos-gptw": "https://www.matera.com/br/cinnecta-agora-e-matera-insights/",
  "recomendacao-de-acoes-ltv":
    "https://www.matera.com/br/blog/sistema-de-recomendacao-de-acao/",
  "dados-para-escalar-marketing":
    "https://www.matera.com/br/blog/recomendacao-de-acoes-para-campanhas-personalizadas",
  "marketing-preditivo":
    "https://www.matera.com/br/blog/predicao-de-comportamento-do-cliente/",
  "interesse-no-mercado-financeiro-digital":
    "https://www.matera.com/br/blog/perfil-do-novo-cliente-bancario/",
  "ltv-cac": "https://www.matera.com/br/blog/ltv-para-crescer-e-escalar",
  "roas-retorno-do-investimento-em-publicidade":
    "https://www.matera.com/br/blog/como-enviar-campanhas-mais-relevantes",
  "google-ads-inteligencia-de-dados":
    "https://www.matera.com/br/blog/como-enviar-campanhas-mais-relevantes",
  "mercado-varejo-quais-sao-os-novos-habitos-e-tendencias":
    "https://www.matera.com/br/blog/comportamento-do-cliente",
  "crescimento-a-partir-do-ltv":
    "https://www.matera.com/br/blog/ltv-para-crescer-e-escalar",
  "persona-e-segmentacao-base-de-clientes":
    "https://www.matera.com/br/blog/segmentacao-de-clientes-em-clusters/",
  "customer-success":
    "https://www.matera.com/br/blog/atendimento-personalizado",
  "fidelizacao-e-o-importante-papel-dos-dados-nesse-processo":
    "https://www.matera.com/br/blog/fidelizacao-de-clientes/",
  "segmentacao-de-clientes-para-fidelizar":
    "https://www.matera.com/br/blog/segmentacao-de-clientes-em-clusters/",
  "cinnecta-insights-enriquecimento-e-novos-filtros":
    "https://www.matera.com/br/cinnecta-agora-e-matera-insights",
  "fraude-de-identidade-impactos":
    "https://www.matera.com/br/cinnecta-agora-e-matera-insights/",
  "segmentacao-de-clientes-crie-estrategias-com-assertividade":
    "https://www.matera.com/br/blog/segmentacao-de-clientes-em-clusters/",
  "estrategias-de-precificacao":
    "https://www.matera.com/br/blog/sensibilidade-ao-preco",
  "estrategia-de-pricing-assertividade-e-rentabilidade":
    "https://www.matera.com/br/blog/sensibilidade-ao-preco",
  "como-calcular-ltv":
    "https://www.matera.com/br/blog/ltv-para-crescer-e-escalar",
  "hub-data-insights-importancia-empresas":
    "https://www.matera.com/br/blog/gerenciamento-de-dados/",
  "vertical-saude-e-comportamento-do-consumidor":
    "https://www.matera.com/br/blog/comportamento-do-cliente",
  "imersao-lideranca":
    "https://www.matera.com/br/cinnecta-agora-e-matera-insights",
  "destinos-mais-prestigiados-pelos-brasileiros-na-virada-do-ano":
    "https://www.matera.com/br/blog/comportamento-do-cliente",
  "ltv-varejo":
    "https://www.matera.com/br/blog/inteligencia-artificial-para-reduzir-custos-de-campanhas-varejo",
  "sistemas-de-recomendacao-de-servicos":
    "https://www.matera.com/br/blog/sistema-de-recomendacao-de-acao/",
  "customer-trends-insights-na-velocidade-ideal-para-seu-negocio":
    "https://www.matera.com/br/cinnecta-agora-e-matera-insights/",
  "campanhas-de-natal":
    "https://www.matera.com/br/blog/inteligencia-artificial-para-reduzir-custos-de-campanhas-varejo",
  "inteligencia-de-marketing":
    "https://www.matera.com/br/blog/marketing-de-fidelizacao/",
  "como-ser-data-driven-em-marketing":
    "https://www.matera.com/br/blog/cultura-data-driven",
  "customer-marketing":
    "https://www.matera.com/br/blog/marketing-de-fidelizacao/",
  "marketing-de-relacionamento":
    "https://www.matera.com/br/blog/marketing-de-fidelizacao/",
  "customer-health-score":
    "https://www.matera.com/br/blog/fidelizacao-de-clientes/",
  "lgpd-no-marketing":
    "https://www.matera.com/br/cinnecta-agora-e-matera-insights",
  "revenue-marketing":
    "https://www.matera.com/br/blog/ltv-para-crescer-e-escalar",
  "mrr-monthly-recurring-revenue":
    "https://www.matera.com/br/blog/ltv-para-crescer-e-escalar",
  "precificacao-dinamica":
    "https://www.matera.com/br/cinnecta-agora-e-matera-insights",
  "ciencia-de-dados": "https://www.matera.com/br/blog/gerenciamento-de-dados/",
  "cinnecta-insights-novos-filtros-enriquecimento":
    "https://www.matera.com/br/cinnecta-agora-e-matera-insights/",
  "analise-de-sobrevivencia-de-clientes":
    "https://www.matera.com/br/blog/acompanhamento-taxa-de-churn/",
  "engenharia-de-dados":
    "https://www.matera.com/br/blog/gerenciamento-de-dados/",
  "inteligencia-competitiva":
    "https://www.matera.com/br/blog/area-de-dados-e-metas-de-negocio",
  "agile-marketing": "https://www.matera.com/br/blog/times-multidisciplinares/",
  "inteligencia-artificial-ia-big-data":
    "https://www.matera.com/br/blog/diferentes-tipos-de-inteligencia-artificial/",
  "como-otimizar-o-ltv":
    "https://www.matera.com/br/blog/ltv-para-crescer-e-escalar",
  "ltv-e-roi": "https://www.matera.com/br/blog/ltv-para-crescer-e-escalar",
  "como-incluir-LTV-do-cliente-na-estrategia-de-relacionamento":
    "https://www.matera.com/br/blog/marketing-de-fidelizacao/",
  "marketing-de-retencao":
    "https://www.matera.com/br/blog/retencao-reativa-de-clientes",
  "experiencia-do-cliente-e-LTV":
    "https://www.matera.com/br/blog/experiencia-do-cliente",
  "estrategias-de-segmentacao":
    "https://www.matera.com/br/blog/segmentacao-de-clientes-em-clusters/",
  "programa-de-estagio-cinnecta":
    "https://www.matera.com/br/cinnecta-agora-e-matera-insights/",
  "cinnecta-revenue-fidelizacao":
    "https://www.matera.com/br/cinnecta-agora-e-matera-insights/",
  "estrategias-de-retencao":
    "https://www.matera.com/br/blog/retencao-de-clientes",
  "reducao-de-custos-com-cultura-de-dados":
    "https://www.matera.com/br/blog/analise-de-dados-para-aumento-de-receita",
  "ferramenta-de-sensibilidade-ao-preco":
    "https://www.matera.com/br/blog/sensibilidade-ao-preco",
  "machine-learning-e-ltv":
    "https://www.matera.com/br/blog/machine-learning-aplicado-a-dados-de-clientes",
  "como-diminuir-o-cac":
    "https://www.matera.com/br/blog/custo-de-aquisicao-de-clientes-cac/",
  "gptw-2023-cinnecta-primeiro-lugar":
    "https://www.matera.com/br/cinnecta-agora-e-matera-insights/",
  "elasticidade-de-preco":
    "https://www.matera.com/br/blog/sensibilidade-ao-preco",
  "rentabilizacao-fora-das-datas-comemorativas":
    "https://www.matera.com/br/blog/rentabilizacao-de-clientes/",
  "precificacao-inteligente":
    "https://www.matera.com/br/blog/sensibilidade-ao-preco",
  "cinnecta-no-minascoders":
    "https://www.matera.com/br/cinnecta-agora-e-matera-insights/",
  "clv-customer-lifetime-value":
    "https://www.matera.com/br/cinnecta-agora-e-matera-insights",
  "diferenca-ltv-potencial-ltv-previsto":
    "https://www.matera.com/br/cinnecta-agora-e-matera-insights",
  "inteligencia-de-dados":
    "https://www.matera.com/br/cinnecta-agora-e-matera-insights",
  "plataforma-cinnecta-revenue":
    "https://www.matera.com/br/cinnecta-agora-e-matera-insights/",
  "analise-de-dados-para-tomada-de-decisao":
    "https://www.matera.com/br/blog/analise-de-dados/",
  "drex-novo-projeto-do-banco-central-do-brasil":
    "https://www.matera.com/br/blog/drex-o-que-e/",
  "como-personalizar-precos-com-base-no-comportamento-do-cliente":
    "https://www.matera.com/br/blog/sensibilidade-ao-preco",
  "ticket-medio":
    "https://www.matera.com/br/blog/maximizacao-do-valor-do-cliente/",
  "estrategias-de-fidelizacao-personalizada":
    "https://www.matera.com/br/blog/fidelizacao-de-clientes/",
  "mercado-financeiro-inteligencia-de-dados":
    "https://www.matera.com/br/blog/ia-no-mercado-financeiro/",
  "embedded-finance": "https://www.matera.com/br/blog/embedded-finance/",
  "desafios-e-inovacoes-na-concessao-de-credito":
    "https://www.matera.com/br/blog/como-inovar-na-concessao-de-credito",
  "segmentacao-de-clientes":
    "https://www.matera.com/br/blog/segmentacao-de-clientes-em-clusters/",
  "segmentacao-e-negocios-de-alto-valor":
    "https://www.matera.com/br/blog/segmentacao-de-clientes-em-clusters/",
  "analise-de-dados-do-cliente":
    "https://www.matera.com/br/blog/analise-de-dados/",
  "marketing-de-fidelizacao":
    "https://www.matera.com/br/blog/fidelizacao-de-clientes/",
};

const materaPostsSlugsArray = [
  "analise-de-credito",
  "analise-de-inadimplencia",
  "como-inovar-na-concessao-de-credito",
  "score-de-credito-personalizado",
  "rentabilizacao-de-clientes",
  "fidelizacao-de-clientes",
  "inteligencia-artificial-para-entendimento-do-cliente",
  "sensibilidade-ao-preco",
  "predicao-de-churn",
  "analise-do-comportamento-do-cliente",
  "personalizacao-em-escala",
  "maximizacao-do-valor-do-cliente",
  "nbo-next-best-offer",
  "cross-sell",
  "upsell-o-que-e",
  "ia-na-concessao-de-credito",
  "ia-no-mercado-financeiro",
  "ia-no-ciclo-de-vida-do-cliente",
  "analise-de-dados",
  "principais-desafios-da-aplicacao-de-IA-nos-negocios",
  "volume-de-vendas",
  "perfil-do-novo-cliente-bancario",
  "retencao-reativa-de-clientes",
  "analise-descritiva",
  "perfil-do-cliente",
  "clusterizacao-de-clientes",
  "personalizacao-customizacao",
  "ciclo-de-vida-do-cliente",
  "dados-internos-e-externos",
  "perfil-do-inadimplente",
  "segmentacao-de-clientes-em-clusters",
  "dados-demograficos",
  "gestao-ciclo-de-vida-do-cliente",
  "nba-next-best-action",
  "analise-prescritiva",
  "comportamento-do-cliente",
  "algoritmos-preditivos",
  "retencao-de-clientes",
  "democratizacao-servicos-financeiros",
  "principalidade-do-cliente-no-credito",
  "knn-k-nearest-neighbors",
  "gerenciamento-de-dados",
  "times-multidisciplinares",
  "aquisicao-e-retencao-clientes",
];
export default function Post({ params, pageContext }) {
  const [open, setOpen] = useState(false);
  const { data: listPosts, isSuccess } = useListPost("date_DESC", 3, 0);
  const htmlElement = useRef(null);

  const slug =
    typeof window !== "undefined" && window?.location?.pathname?.split("/")[2];
  const { data, redirect } = useBlog(slug);
  const materaPostsSlugs = new Set(materaPostsSlugsArray);
  const isMateraSlug = materaPostsSlugs.has(slug);

  useEffect(() => {
    if (data?.post?.content?.html) {
      clickableSummary();
    }
  }, [data?.post?.content?.html]);

  useEffect(() => {
    if (isMateraSlug) {
      window.location.replace(`https://www.matera.com/br/blog/${slug}`);
      return;
    }
    if (noRedirectPosts[slug]) {
      window.location.replace(noRedirectPosts[slug]);
      return;
    }
    window.location.replace(
      "https://www.matera.com/br/cinnecta-agora-e-matera-insights"
    );
  }, [slug]);

  if (redirect) {
    window.location.href = redirect;

    return <></>;
  }

  const url = `https://cinnecta.com/conteudos/${slug}`;
  const socialShareMap = {
    linkedin: {
      shareUrl: "https://www.linkedin.com/shareArticle?mini=true&url=",
      display: <BsLinkedin color="var(--blue-50)" size="20" />,
    },
    facebook: {
      shareUrl: "https://www.facebook.com/sharer/sharer.php?u=",
      display: <BsFacebook color="var(--blue-50)" size="20" />,
    },
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  const socialRedirect = () => {
    return Object.entries(socialShareMap).map(([key, value]) => {
      if (key === "whatsapp") {
        return (
          <a
            target="_blank"
            rel="noreferrer"
            key={key}
            href={`${value.shareUrl}`}
          >
            {value.display}
          </a>
        );
      } else {
        return (
          <a
            target="_blank"
            rel="noreferrer"
            key={key}
            href={`${value.shareUrl}${url}`}
          >
            {value.display}
          </a>
        );
      }
    });
  };

  const treatImageLink = (htmlContent) => {
    if (htmlContent && data?.post.linkImages?.length > 0) {
      const images = data.post.linkImages.map((linkImage) => {
        const imageStartIdentifier = `<img src="${linkImage.url}"`;
        const imageEndIdentifier = "/>";
        const imageStartPosition = htmlContent.indexOf(imageStartIdentifier);
        const imageEndPosition = htmlContent.indexOf(
          imageEndIdentifier,
          imageStartPosition
        );

        let imageTag;

        if (imageStartPosition !== -1) {
          imageTag = htmlContent.substring(
            imageStartPosition,
            imageEndPosition + imageEndIdentifier.length
          );
        }

        return {
          imageLink: linkImage.imageLink,
          imageTag,
        };
      });

      images.forEach(({ imageLink, imageTag }) => {
        if (imageTag) {
          htmlContent = htmlContent.replace(
            imageTag,
            `<a href="${imageLink}" target="_blank">${imageTag}</a>`
          );
        }
      });
    }

    return htmlContent;
  };

  const nameAuthor = data?.post?.author?.name
    ? data?.post?.author?.name
    : "Cinnecta";

  const html = treatImageLink(data?.post?.content?.html);

  function clickableSummary() {
    const summaryElement = htmlElement.current.querySelectorAll("li");
    const arrSummaryElements = [...summaryElement];
    const anchors = htmlElement.current.querySelectorAll("h2");
    const arrAnchors = [...anchors];
    return arrAnchors?.forEach((el, i) => {
      if (arrSummaryElements[i]?.firstChild.firstChild?.hash) {
        el.setAttribute(
          "id",
          arrSummaryElements[i].firstChild.firstChild.hash.replace(/#/, "")
        );
      }
    });
  }

  return (
    <Layout>
      {data?.post ? (
        <>
          <ReadingBar title={data?.post?.title} slug={slug} />
          <ContainerPost>
            <h1>{data?.post?.title}</h1>
            <MetaPost>
              <span>
                <RiCalendarCheckLine color="var(--text)" />
                {moment(data?.post?.date)
                  .locale("pt-br")
                  .format("LL")}
              </span>
              <span>
                <RiAccountCircleLine color="var(--text)" />
                {nameAuthor}
              </span>
              <Dropdown
                open={open}
                trigger={
                  <BsShare
                    onClick={handleOpen}
                    color="var(--blue-50)"
                    size="20"
                  />
                }
                menu={socialRedirect()}
              />
            </MetaPost>
            <LineSeparator />
            <ContentPost
              ref={htmlElement}
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </ContainerPost>
          <FooterMostContent>
            <p>Confira mais conteúdos</p>
            <ListMoreContent>
              {isSuccess &&
                listPosts?.posts?.map(
                  ({ slug, title, coverImage, category, form }) => {
                    return (
                      <CardPost
                        isMateraSlug={isMateraSlug}
                        key={slug}
                        noExcerpt
                        post={{ slug, title, coverImage, category, form }}
                      />
                    );
                  }
                )}
            </ListMoreContent>
          </FooterMostContent>
        </>
      ) : null}
    </Layout>
  );
}

export const Head = ({ pageContext }) => {
  return (
    <Seo
      title={pageContext?.title}
      description={pageContext?.description}
      url={pageContext?.coverImage}
      robots={pageContext?.robots}
      keywords={pageContext?.keywords}
    />
  );
};
